import { sendEvent } from 'src/utils/restructure/analytics'
import { makePromoViewG3 } from 'src/utils/restructure/analytics/makePromoViewG3'
import { makeViewPromotion } from 'src/utils/restructure/analytics/makeViewPromotion'

import type { HeroBanner } from './types'

interface BannersToDatalayer extends HeroBanner {
  currentBannerImage: string
}

export function dispatchToDatalayer(banners: BannersToDatalayer[]) {
  const promotions = banners.map((banner, index) => ({
    promotion_id: banner.linkUrl ?? '',
    promotion_name: `Home | ${banner.title?.text}`,
    creative_slot: `${banner.currentBannerImage}`,
    index: index + 1,
  }))

  sendEvent(makePromoViewG3(promotions))
  sendEvent(makeViewPromotion(promotions))
}
