import type { Promotion, ViewPromotionEvent } from './types/promoView'

export const makeViewPromotion = (
  promotions: Promotion[]
): ViewPromotionEvent => {
  return {
    event: 'view_promotion',
    ecommerce: {
      items: promotions,
    },
  }
}
