import type { PromoViewG3, Promotion } from './types/promoView'

export const makePromoViewG3 = (promotions: Promotion[]): PromoViewG3 => {
  return {
    ecommerce: {
      promoView: {
        promotions: promotions.map((promo) => ({
          id: promo.promotion_id,
          creative: promo.creative_slot,
          name: promo.promotion_name,
          position: `slot${promo.index}`,
        })),
      },
    },
  }
}
