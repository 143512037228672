export function LightningIcon({ color = 'white', width = 13, height = 12 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35206 1H16.0289L13.6661 5.72639H19.7174L3.68091 19L5.93061 11.1403H1.28271L6.35206 1ZM7.3079 2.54682L3.78513 9.59343H7.98206L6.5422 14.6238L15.4228 7.27321H11.1636L13.5265 2.54682H7.3079Z"
        fill={color}
        stroke={color}
        strokeWidth="0.628307"
        strokeLinecap="round"
      />
      <path
        d="M14.8211 2.23047H7.43664L3.74438 9.61497H7.43664L6.20589 15.7687L17.2826 7.15347L12.3596 5.92272L14.8211 2.23047Z"
        fill={color}
        stroke={color}
        strokeWidth="0.418872"
      />
    </svg>
  )
}
