import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { useState } from 'react'

import '@splidejs/react-splide/css'
import '@splidejs/react-splide/css/core'

import { Banner } from './Banner'
import './style.scss'
import type { HeroBanner } from './types'

interface Props {
  banners: HeroBanner[]
  section: string
  locationOnPage: string
}

export const HeroSlider = ({ banners, section, locationOnPage }: Props) => {
  const [slideActive, setSlideActive] = useState(0)

  return (
    <Splide
      options={{
        pagination: true,
        type: 'loop',
        autoplay: true,
        interval: 4000,
        easing: 'cubic-bezier(0.42, 0, 0.58, 1)',
        pauseOnFocus: false,
      }}
      hasTrack={false}
      className="relative"
      onVisible={(_, slide) => {
        setSlideActive(slide.index)
      }}
    >
      <SplideTrack>
        {banners.map((bannerProps, index) => {
          return (
            <SplideSlide
              key={index}
              data-index={`element-splid-item${index}`}
              aria-label={bannerProps.title?.text}
              className="h-max"
            >
              <Banner
                {...bannerProps}
                index={index}
                inSlide
                slideActive={slideActive === index}
                section={section}
                locationOnPage={locationOnPage}
              />
            </SplideSlide>
          )
        })}
      </SplideTrack>
      <div className="absolute z-20 bottom-2/4 left-0 w-full restructure-medium-desktop:h-[48px]">
        <div className="custom-controls relative h-2 restructure-medium-desktop:h-12">
          <div className="splide__arrows restructure-medium-desktop:flex h-12">
            <button
              className="splide__arrow splide__arrow--prev"
              type="button"
              aria-label="Previous slide"
              aria-controls="splide01-track"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.9419 4.55806C13.186 4.80214 13.186 5.19786 12.9419 5.44194L8.38388 10L12.9419 14.5581C13.186 14.8021 13.186 15.1979 12.9419 15.4419C12.6979 15.686 12.3021 15.686 12.0581 15.4419L7.05806 10.4419C6.81398 10.1979 6.81398 9.80214 7.05806 9.55806L12.0581 4.55806C12.3021 4.31398 12.6979 4.31398 12.9419 4.55806Z"
                  fill="white"
                />
              </svg>
            </button>
            <button
              className="splide__arrow splide__arrow--next"
              type="button"
              aria-label="Next slide"
              aria-controls="splide01-track"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.05806 4.55806C7.30214 4.31398 7.69786 4.31398 7.94194 4.55806L12.9419 9.55806C13.186 9.80214 13.186 10.1979 12.9419 10.4419L7.94194 15.4419C7.69786 15.686 7.30214 15.686 7.05806 15.4419C6.81398 15.1979 6.81398 14.8021 7.05806 14.5581L11.6161 10L7.05806 5.44194C6.81398 5.19786 6.81398 4.80214 7.05806 4.55806Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Splide>
  )
}
