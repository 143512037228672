import { LightningIcon } from 'src/components/Icons/LightningIcon'
import './countdownExpired.scss'
import { Link } from 'gatsby'
import { dispatchAmplitudeEvent } from 'src/utils/amplitude'
import { getStandardObject } from 'src/utils/amplitude/getStandardObject'
import { getFeatureObject } from 'src/utils/amplitude/getFeatureObject'
import { useInView } from 'react-intersection-observer'
import { useMobile } from 'src/hooks/useMobile'

type CountDownExpiredProps = {
  link: string
  section?: string
  locationOnPage?: string
  buttonText?: string
  expiredTitle?: string
  expiredDescription?: string
}

const CountDownExpired = ({
  link,
  section,
  locationOnPage,
  buttonText,
  expiredTitle,
  expiredDescription,
}: CountDownExpiredProps) => {
  const { isMobile } = useMobile()
  const standardObject = getStandardObject()

  const featureObject =
    section && locationOnPage
      ? getFeatureObject({
          section,
          locationOnPage,
          name: 'Começou a Black Friday decathlon',
        })
      : null

  const { ref } = useInView({
    threshold: 0.7,
    triggerOnce: true,
    onChange: (inView) => {
      if (!inView) {
        return
      }

      sendPromoFeatureViewedEvent()
    },
  })

  function sendPromoFeatureViewedEvent() {
    if (!featureObject) {
      return
    }

    const eventData = {
      ...standardObject,
      ...featureObject,
    }

    dispatchAmplitudeEvent({ eventName: 'Promo Feature Viewed', eventData })
  }

  const sendAmplitudeClickEvent = () => {
    if (!featureObject) {
      return
    }

    dispatchAmplitudeEvent({
      eventName: 'Promotional Element Selected',
      eventData: {
        ...standardObject,
        ...featureObject,
        'promotional element name': 'BlackFriday Countdown',
        'promotional element index': 0,
      },
    })
  }

  return (
    <section ref={ref} className="countdown-expired-container">
      <div className="countdown-expired-content">
        <div className="countdown-expired-text">
          <h2>{expiredTitle}</h2>
          <p>{expiredDescription}</p>
        </div>
        <div className="countdown-expired-cta">
          <Link
            onClick={sendAmplitudeClickEvent}
            className="countdown-expired-cta-link"
            to={link}
          >
            <LightningIcon
              width={isMobile ? 10 : 18}
              height={isMobile ? 10 : 18}
              color="#101010"
            />
            {buttonText}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default CountDownExpired
