import { array, boolean, object, string } from 'zod'

import type { HeroBannerHome } from './types'

export function useHeroBanner() {
  function validateProps(props: HeroBannerHome | null) {
    try {
      const schema = object({
        enableSession: boolean(),
        banners: array(
          object({
            image: object({
              mobile: string(),
              tablet: string(),
              desktop: string(),
            }),
            linkUrl: string(),
            title: object({
              text: string(),
              textColor: string(),
            }),
            text: object({
              text: string(),
              textColor: string(),
            }),
            button: object({
              text: string(),
              textColor: string(),
            }),
          })
        ),
      })

      schema.parse(props)

      return true
    } catch (err) {
      console.warn('Invalid HeroBanner Props', err)

      return false
    }
  }

  return {
    validateProps,
  }
}
